import React from "react"
import {Layout} from "antd"
import {Link} from "react-router-dom"

import IntlMessages from "../../util/IntlMessages"

const {Header} = Layout

const Topbar = () => {
	return (
		<Header style={{backgroundColor: '#00995d', color: '#fff', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)'}}>
			<Link to="/" className="gx-d-block gx-d-sm-none gx-pointer">
				<img alt="" src="https://api.unimednatal.com.br/api/imgs/logos/unimed_quad.png" style={{width: 40}}/>
			</Link>
			<Link to="/" className="gx-d-none gx-d-sm-block gx-pointer">
				<img alt="" src="https://api.unimednatal.com.br/api/imgs/logos/logo2.png" style={{height: 45}}/>
			</Link>
			<div className="title gx-fs-xxl" style={{ paddingLeft: "10px" }}>
				<IntlMessages id="topbar.titulo" />
			</div>
			<ul className="gx-header-notifications gx-ml-auto">
				
			</ul>
		</Header>
	)
}

export default Topbar
