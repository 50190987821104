import axios from 'axios';
import { urlApiServicos, urlApi, urlApiRails, appName, codAplicacao } from "util/config";

export const httpClient = axios.create({
	baseURL: urlApiRails,
	headers: {
		'Content-Type': 'application/json',
		AppName: appName,
		CodAplicacao: codAplicacao
	},
});
	
export const apiServicos = axios.create({
	baseURL: urlApiServicos,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const api = axios.create({
	baseURL: urlApi,
	headers: {
		"Content-Type": "application/json"
	}
});